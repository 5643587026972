.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
}
.containerHeader{
  margin: 0;
  padding: 0;
}
.navbar{
  margin: 0;
  background-color: #e30614;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2vw 5%;
  color:white;
}
.imgHeader{
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  overflow: hidden;
}
.imgHeader img{
  width:100%;
  height:auto;
  object-fit:cover;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.optionsLanguage{
  width: 28vw;
  background-color: #008a1d;
  column-gap: 20px;
  margin-right: 40px;
  margin-left: auto;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.content{
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 10%;
  align-items: center;
}
.lang{
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.lang img{
  width: 3vw;
}
.lang p{
  font-size: 1.8vw;
  color:white;
  text-transform: capitalize;
}
.link{
  text-decoration:none;
  color: white;
  font-size: 2vw;
}
@media screen and (max-width: 768px) {
  .link{
    font-size: 12px;
  } 
}