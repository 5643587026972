.footer {
    position: relative;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center;
    color: black;
    font-size: 12px;
  }
.color {
  background-color: #008a1d;
  padding: 20px 0px 0px 0px;
}
.socialMedia {
  display: flex;
  justify-content: center;
  flex-direction: column;
  bottom: 5%;
  position: fixed;
  right: 2%;
  row-gap: 15px;
}
.icon img {
  cursor: pointer;
  background-color: white;
  border-radius: 15px;
}