.main {
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #e30614;
}
.imgContentMain{
  /*padding: 0 5%;*/
}

.imgContentMain img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.containerInfoMain{
  width: 100%;
  overflow: hidden;
  padding-bottom: 20px;
}
.franjaServicesMain{
  margin-bottom: 50px;
  background-color: #e30614;
  transform: rotate(5deg);
  width: 110%;
  margin-left: -5%;
  margin-top: 15%;
  position: relative;
}
.contentInfo {
  width: 90%;
  margin: auto;
  transform: rotate(-5deg);
}
.textInfo {
  align-items: center;
  margin-top: -3%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}
.imgKimia {
  width: 100%;
  position: relative;
  top: -10px;
  flex: 1;
  min-width: 360px;
}
.imgKimia.mobile {
  width: 100%;
  position: relative;
  top: -10px;
  display: none;
  flex: 1;
}
.imgKimia img {
  height: auto;/*400px*/
  width: 100%;/*500px*/
  object-fit: cover;
}
.descriptionServiceMain {
  width: 100%;
  flex: 1;
  padding: 60px;
  min-width: 320px;
}
.titleSecund h3 {
  color: white;
  font-size: 30px;
  margin-bottom: 40px;
  width: 80%;
  font-weight: 400;
  letter-spacing: 1px;
}
.descriptionServiceMain p {
  color: white;
  font-size: 18px;
  width: 90%;
  text-align: justify;
}
.imgKimiaPerson {
  flex: 1;
  min-width: 320px;
}
.imgKimiaPerson img {
  height: auto;
  width: 33%;
  position: relative;
  top: 40px;
  margin-right: 40px;
}