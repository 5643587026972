.containerProduction {
    margin-top: 0px;
    overflow: hidden;
}
.title h3 {
    color: #01B12F;
    text-align: center;
    font-size: 40px;
    margin-bottom: 40px;
    font-weight: 400;
  }
.contentProduction {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: 50px;
    width: 90%;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
}
.contentProduction a{
    text-decoration: none;
}
.infoProduction {
    height: 270px;
    width: 330px;
    max-width: 80%;
    margin: auto;
    cursor: pointer;
}
.infoProduction img {
    width: 100%;
    height: 80%;
    box-shadow: -11px 8px 0px 0px #CDCDCD;
    border-radius: 40px;
    margin-bottom: 20px;
    object-fit: fill;
    transition: 0.3s;
}
.infoProduction p {
    color: #007F10;
    font-size: 16px;
    text-align: center;
    font-family: "Arial MT","arial-Rounded";
    margin: auto;
    letter-spacing: 1px;
}
.containerServices {
    padding-top: 0px;
    overflow: hidden;
}
.franjaServices {
    margin-top: 3%;
    background-color: #e30614;
    transform: rotate(5deg);
    width: 110%;
    margin-left: -5%;
    padding: 4%;
    position: relative;
}
.franjaServicesPopUp {
    margin-top: 3%;
    background-color: #e30614;
    padding: 4%;
    position: relative;
}
.contentInfoService {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
    column-gap: 10%;
    transform: rotate(-5deg);
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5%;
}
.textInfoService {
    align-items: center;
    margin-top: -3%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
}
.titleService h3 {
    font-size: 30px;
    font-weight: 100;
    letter-spacing: 1px;
    color: white;
}
.titleService.right h3{
    color: #007F10;
    margin-top: 30px;
}
.descriptionService {
    width: 100%;
    flex: 1;
    min-width: 320px;
}
.descriptionService p{
    color: white;
    font-size: 18px;
    text-align: justify;
}
.descriptionService.black {
    margin-top: 15px;
    color: #0000;
    text-align: center;
}
.descriptionService.black p {
    color: #000;
    font-size: 18px;
}
.imgService.desktop{
    flex:1;
    max-width: 420px;
}
.imgService iframe {
    height: auto;
    width: 100%;
    border-radius: 30px;
    box-shadow: 10px 10px 0px 0px #9f0000;
    aspect-ratio: 16/9;
}
.deg {
    margin-top: 5px;
    transform: rotate(0deg);
}
.imgDesign{
    max-width: 420px;
}
.imgDesign img {
    height: auto;
    width: 100%;
    object-fit: fill;
}
.franjaServices.degArts.changeColor {
    transform: rotate(-5deg);
    background-color: #008a1d;
}
.contentInfoService.degContentArts {
    transform: rotate(5deg);
}
.marginTopArts {
    margin-top: 0px;
    max-width: 420px;
}
.imgConceptArts img {
    border-radius: 13px;
    box-shadow: 10px 10px 0px 0px #006d10;
    height: auto;
    width: 100%;
    object-fit: fill;
}
.imgGuiones.desktop{
    max-width: 420px;
    flex:1;
}
.imgGuiones img {
    height: auto;
    width: 100%;
    border-radius: 30px;
    min-width: 320px;
}
.contentStoryBoards {
    transform: rotate(-5deg);
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2%;
    margin: auto;
    display: flex;
}
.textInfoHistory {
    margin-bottom: 15px;
    margin-left: 2%;
    text-align: left;
}
.imgStory{
    max-width: 90%;
}
.imgStory img {
    width: 100%;
    height: auto;
}
.contentPostproduction {
    margin-top: -5%;
    height: 410px;
    width: 100%;
    background-image: url("/public/images/services/Geo Robots Imagen_cielo fondo.jpg");
    z-index: -1;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.imgContent {
    height: auto;
    width: 100%;
    flex: 1;
}
.imgContent img {
    width: 30%;
    height: auto;
    position: absolute;
    bottom: 20px;
    left: 0px;
}
.textPostproduction {
    align-items: center;
    text-align: justify;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
}
.textPostproduction img {
    height: auto;
  flex: 1;
  max-width: 520px;
}
.postproductionTitle {
    margin: 20px;
    flex: 1;
}
.postproductionTitle h3 {
    color: #008a1d;
    margin-bottom: 15px;
    text-align: center;
}
.contentContact {
    background-color: #008a1d;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.buttonContact {
    text-align: center;
}
.buttonContact a {
    background-color: #fff;
    padding: 5px 35px;
    color: #e00613;
    font-size: 42px;
    border-radius: 25px;
    box-shadow: -8px 10px 4px 0px #00000040;
    text-decoration: none;
}
.rot{
    transform: rotate(5deg);
}
.black{
    color:#000;
}
.containerContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}
.titlecontact {
    color: #e10512;
    text-align: center;
    font-size: 50px;
    margin-bottom: 50px;
}
form {
    width: 100%;
}
.containerForm {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    background-color: #008a1d;
    margin: auto;
      margin-bottom: auto;
    margin-bottom: auto;
    border-radius: 60px;
    padding: 3%;
    row-gap: 35px;
    margin-bottom: 30px;
}
@media screen and (max-width: 768px){
    .containerForm {
        width: 90%;
        padding: 8% 3%;
    }
}
.optionsForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin-left: 15px;
}
.optionsForm p {
    font-size: 30px;
    color: white;
    letter-spacing: 2px;
}
@media screen and (max-width: 768px){
    .optionsForm p {
        font-size: 26px;
    }
}
.optionsForm input {
    width: 60%;
    border: 0;
    border-radius: 22px;
    height: 60px;
    box-shadow: -10px 6px 0px 0px #006c0d;
    color: #000;
    padding-left: 5%;
    outline: none;
  }
.optionsFormMessaje {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin-left: 15px;
    row-gap: 35px;
}
.optionsFormMessaje p {
    font-size: 30px;
    color: white;
    letter-spacing: 2px;
}
.optionsFormMessaje textarea {
    width: 75%;
    border-radius: 27px;
    min-height: 60px;
    border: 0;
    box-shadow: -10px 7px 0px 0px #006c0d;
    color: #000;
    padding-left: 60px;
    padding-top: 60px;
    outline: none;
}
@media screen and (max-width: 768px) {
    .optionsFormMessaje textarea {
        padding: 10px 10px;
    }
}
.contentSend {
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonSend .sendsDiv {
    background-color: #fff;
    padding: 10px 40px;
    color: #e30614;
    font-size: 25px;
    border-radius: 14px;
    box-shadow: -8px 10px 4px 0px #00000040;
    cursor: pointer;
    outline: none;
    border: 0;
}
.titleUs{
    text-align: center;
    margin-bottom: 50px;
    margin-top: 70px;
}
.titleUs h3{
    font-family: 'Arnold 2.1', sans-serif;
    color: #e30614;
    font-size: 40px;
    font-weight: 100;
    letter-spacing: 3px;
}
.containerUS{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contentUs{
    background-color: #008a1d;
    border-radius: 0px 5vw 5vw 0px;
    padding: 4.5vw 0;
    width: 55vw;
    display: flex;
    align-items: center;
}
.usText{
    text-align: center;
    width: 70%;
    margin: auto;
}
@media screen and (max-width: 768px){
    .usText{
        width: 80%;
    }
}
.usText h3{
    font-family: 'Arnold 2.1', sans-serif;
    color: white;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 100;
    letter-spacing: 3px;
    text-align: center;
}
@media screen and (max-width: 768px){
    .usText h3{
        font-size: 26px;
    }
}
.usText p {
    font-size: 18px;
    color: white;
    font-family: "Arial MT","arial-Rounded";
    width: 80%;
    text-align: justify;
    margin: auto;
}
@media screen and (max-width: 768px) {
    .usText p {
        font-size: 16px;
        width: 100%;
    }
}
.imgUs {
    background: white;
    border-radius: 50%;
    height: 26vw;
    width: 26vw;
    text-align: center;
    margin-right: 5%;
}
.imgUs img {
    height: 26vw;
    margin-top: -15px;
}
.descriptionUs{
    width: 100%;
    background-color: #e30614;
}
.titleUsWhite{
    text-align: center;
    margin-bottom: 50px;
    margin-top: 70px;
}
.titleUsWhite h3{
    font-family: 'Arnold 2.1', sans-serif;
    color: white;
    font-size: 40px;
    font-weight: 100;
    letter-spacing: 3px;
    padding: 20px 6vw 0px 6vw;
}
@media screen and (max-width: 768px) {
    .titleUsWhite h3{
        font-family: 'Arnold 2.1', sans-serif;
        color: white;
        font-size: 26px;
        font-weight: 100;
        letter-spacing: 3px;
        padding: 20px 6vw 0px 6vw;
    }
}
.titleUsWhite p {
    font-size: 2.4vw;
    color: white;
    font-family: "Arial MT","arial-Rounded";
    width: 63%;
    text-align: justify;
    margin: auto;
    padding-bottom: 40px;
}
@media screen and (max-width: 768px) {
    .titleUsWhite p {
        font-size: 16px;
    }
}
.leftImgUs {
    background: white;
    border-radius: 50%;
    height: 26vw;
    width: 26vw;
    text-align: center;
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.leftImgUs img {
    height: 32.5vw;
    margin-top: -15px;
}

.leftContentUs{
    background-color: white;
    border-radius: 0px 5vw 5vw 0px;
    padding: 4.5vw 0;
    width: 55vw;
    display: flex;
    align-items: center;
}
.leftUsText{
    text-align: center;
    width: 70%;
}
.leftUsText h3{
    font-family: 'Arnold 2.1', sans-serif;
    color: white;
    margin-bottom: 20px;
    font-size: 4vw;
    font-weight: 100;
    letter-spacing: 3px;
    text-align: center;
    display: flex;
    flex-direction: row;
    position: relative;
}
@media screen and (max-width: 768px) {
    .leftUsText h3{
        font-size: 26px;
    }
}
.leftUsText div{
    display: flex;
}
.leftUsText h3:first-child{
    font-family: 'Arnold 2.1', sans-serif;
    color:#008a1d;
}
.leftUsText h3:nth-child(2n){
    font-family: 'Arnold 2.1', sans-serif;
    color: #e30614;
}
.leftUsText p {
    font-size: 1.9vw;
    color: #008a1d;
    font-family: "Arial MT","arial-Rounded";
    text-align: justify;
    margin: auto;
}
@media screen and (max-width: 768px){
    .leftUsText p {
        font-size: 16px;
    }
    .leftUsText{
        width: 90%;
    }
}
.rightImgUs {
    background: white;
    border-radius: 50%;
    height: 26vw;
    width: 26vw;
    text-align: center;
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.rightImgUs img {
    height: 32.5vw;
    margin-top: -15px;
}
.rightImgUs2 {
    background: white;
    border-radius: 50%;
    height: 26vw;
    width: 26vw;
    text-align: center;
    margin-right: 10%;
    margin-left: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.rightImgUs2 img {
    height: 16vw;
    margin-top: -15px;
}

.rightContentUs{
    background-color: white;
    border-radius: 0px 5vw 5vw 0px;
    padding: 4.5vw 0;
    width: 55vw;
    display: flex;
    align-items: center;
}
.rightUsText{
    text-align: center;
    width: 70%;
    margin-left: 5%;
}

.rightUsText h3{
    font-family: 'Arnold 2.1', sans-serif;
    color: white;
    margin-bottom: 20px;
    font-size: 4vw;
    font-weight: 100;
    letter-spacing: 3px;
    text-align: center;
    display: flex;
    flex-direction: row;
    position: relative;
}

.rightUsText div{
    display: flex;
    margin-left: 5vw;
}
.rightUsText h3:first-child{
    font-family: 'Arnold 2.1', sans-serif;
    color:#008a1d;
}
.rightUsText h3:nth-child(2n){
    font-family: 'Arnold 2.1', sans-serif;
    color: #e30614;
}
.rightUsText p {
    font-size: 1.9vw;
    color: #008a1d;
    font-family: "Arial MT","arial-Rounded";
    text-align: justify;
    margin-left: 5vw;
}
@media screen and (max-width: 768px){
    .rightUsText p {
        font-size: 16px;
    }
    .rightUsText{
        width: 90%;
    }
    .rightUsText h3{
        font-size: 26px;
    }
}
.containerAwards {
    height: 350px;
    width: 100%;
    overflow: hidden;
}
.elipse {
    background-color: #008a1d;
    border-radius: 50% 50% 0 0;
    width: 110%;
    height: 260%;/*900px*/
    padding-bottom: 109px;
    margin-left: -5%;
    margin-right: -5%;
}
@media screen and (max-width: 768px) {
    .elipse {
      height: 350px;
    }
  }
.InfoElipse {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
    justify-content: center;
    height: 350px;
    row-gap: 53px;
}
@media screen and (max-width: 768px) {
    .InfoElipse {
      width: 90%;
    }
  }
.titleAwards h3 {
    color: white;
    font-family: 'Arnold 2.1', sans-serif;
    font-size: 40px;
    text-align: center;
    font-weight: 100;
    letter-spacing: 3px;
}
@media screen and (max-width: 768px) {
    .titleAwards h3 {
        font-size: 26px;
        width: 70%;
        margin: auto;
        letter-spacing: 0px;
    }
  }
.textAwards p {
    color: white;
    font-size: 1.3vw;
    font-family: "Arial MT","arial-Rounded";
    text-align: justify;
    margin: auto;
    width: 60%;
}
@media screen and (max-width: 768px) {
    .textAwards p {
        font-size: 16px;
        width: 70%;
        padding: 0 5%;
    }
  }
.containerGallery {
    margin-top: 30px;
    overflow: hidden;
}
.contentGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1.7vw;
    row-gap: 50px;
    width: 90%;
    margin: auto;
      margin-bottom: auto;
    margin-bottom: 50px;
}
.infoGallery {
    width: 30%;
    max-width: 90%;
    height: 250px;
}
.infoGallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: -9px 11px 0px 0px #B3B3B3;
    box-shadow: -14px 16px 0px -2px #b3b4b3ed;
    border-radius: 27px;
    transition: 0.3s;
}
.imgHome {
    width: 100%;
    text-align: center;
}
.imgHome img {
    height: auto;
    width: 30%;
    object-fit: cover;
}
.titlesText {
    color: #4b1f03 !important;
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
}
.textSinopsis p {
    font-size: 18px;
    font-family: "Arial MT","arial-Rounded";
    text-align: justify;
    padding: 0 5%;
    color: #333;
    line-height: 1.5;
}
.contentInfoImg {
    margin: auto;
    display: flex;
    align-items: center;
    width: 30%;
}
.contentInfo {
    width: 100%;
    margin: auto;
  }
.imgidea {
    display: flex;
    padding: 0 5%;
    max-width: 62%;
    margin: auto;
}
.imgidea img {
    width: 100%;
    height: auto;
}
.containerCharacters {
    margin-top: 80px;
    width: 100%;
}
.containerDescriptionA {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    column-gap: 20px;
    margin-bottom: 20px;
    flex-direction: column;
}
.textInfo {
    width: 100%;
}
.titleChart {
    margin-bottom: 20px;
    width: 100%;
}
.titleChart h3 {
    color: white;
    text-align: center;
    font-size: 42px;
    background-color: #e30614;
    padding: 1% 0;
    font-weight: inherit;
}
.contentInfoCharacters {
    margin: auto;
}
.imgA img {
    width: 60%;
    margin: 5px;
}
.txtA h3 {
    color: #bb5a56;
    font-size: 42px;
    font-weight: inherit;
    margin: 20px 0;
    text-align: center;
  }
.contentA {
    width: 70%;
    padding: 2% 0;
}
.contentA p {
    text-align: justify;
    font-size: 18px;
    line-height: 1.5;
    font-family: "Arial MT","arial-Rounded";
    color: #000000;
}